import React from 'react'
import { css } from '@emotion/core'

class Textarea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: ''};

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    render() {
        return (
            <textarea
                id={this.props.id}
                type={this.props.type}
                name={this.props.name}
                required={this.props.required}
                onChange={this.handleChange}
                css={css`
                    display: block;
                    width: 100%;
                    appearance: none;
                    box-shadow: none;
                    background-color: var(--color-bg);
                    border: 0;
                    border-radius: 0;
                    font-size: 1em;
                    padding: 1em;
                    outline: none;
                    min-height: 10em;
                `}
            >{this.state.value}</textarea>
        )
    }
}

export default Textarea
