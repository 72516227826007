import React from 'react'
import { css } from '@emotion/core'

export default (props) => (
    <label
        htmlFor={props.for}
        css={css`
            display: block;
            font-family: var(--font-serif);
        `}
    >{props.children}</label>
)
