import React from 'react'
import { css } from '@emotion/core'
import Button from './Button'
import Input from './Input'
import Textarea from './Textarea'
import Label from './Label'

const Control = (props) => (
    <p
        className={props.className}
        css={css`
            position: relative;
            margin-bottom: 2.5em;
            & input ~ label {
                position: absolute;
                bottom: 0.25em;
                transform-origin: left bottom;
                transition: transform 0.2s ease;
            }
            & input:focus ~ label,
            & input:not([value=""]) ~ label {
                transform: translate3d(0,calc( -100% - 0.25em),0) scale(0.9);
            }
        `}
    >{props.children}</p>
)

export default () => (
    <form name="quiz" method="POST" action="/thanks/" data-netlify="true"
        css={css`
            max-width: 40em;
            margin-right: auto;
            margin-left: auto;
            p.radios label {
                display: block;
                &:not(:last-of-type) {
                    margin-bottom: 0.5em;
                }
                &:first-of-type {
                    font-family: var(--font-serif);
                    font-weight: bold;
                }
                &:not(:first-of-type) {
                    position: relative;
                    padding-left: 1.5em;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                    input {
                        position: absolute;
                        top: 0.2em;
                        left: 0;
                    }
                }
            }
            @media ( min-width: 48em ) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                p {
                    width: calc(50% - 0.75em);
                    margin-bottom: 1em;
                }
                .wide {
                    width: 100%;
                }
            }
        `}
    >
        <input type="hidden" name="form-name" value="quiz" />
        <p class="wide radios">
            <label>How would you describe you, your spouse and your child's learning environment so far?</label>

            <label><input type="radio" name="Learning Environment" value="Highly traditional but time for a change" /> Highly traditional but time for a change</label>
            <label><input type="radio" name="Learning Environment" value="A mix of traditional and non-traditional learning" /> A mix of traditional and non-traditional learning</label>
            <label><input type="radio" name="Learning Environment" value="Non-traditional" /> Non-traditional</label>
        </p>
        <p class="wide radios">
            <label>What excites your family most about Acton Academy?</label>

            <label><input type="radio" name="Excited About" value="Self-paced Core Skills so our child can learn as quickly as possible" /> Self-paced Core Skills so our child can learn as quickly as possible</label>
            <label><input type="radio" name="Excited About" value="Challenging Quests and Socratic Discussions that deliver 21st Century Skills" /> Challenging Quests and Socratic Discussions that deliver 21st Century Skills</label>
            <label><input type="radio" name="Excited About" value="The Hero's Journey, Learning Badges and Apprenticeships that help Eagles find a calling that will change the world" /> The Hero's Journey, Learning Badges and Apprenticeships that help Eagles find a calling that will change the world</label>
            <label><input type="radio" name="Excited About" value="A multi-age Learner Driven Community that will motivate our child" /> A multi-age Learner Driven Community that will motivate our child</label>
        </p>
        <p class="wide radios">
            <label>Do you feel like you and your family have been on a passionate Hero's Journey in life or are ready to start?</label>

            <label><input type="radio" name="Ready to Start" value="No" /> No</label>
            <label><input type="radio" name="Ready to Start" value="Still Exploring" /> Still Exploring</label>
            <label><input type="radio" name="Ready to Start" value="Absolutely ready to start" /> Absolutely ready to start</label>
        </p>
        <p class="wide radios">
            <label>What concerns you the most about transitioning to Acton Academy?</label>

            <label><input type="radio" name="Concerns" value="The work and rigor required" /> The work and rigor required</label>
            <label><input type="radio" name="Concerns" value="Whether I am ready to trust my child as an independent learner" /> Whether I am ready to trust my child as an independent learner</label>
            <label><input type="radio" name="Concerns" value="The lack of a traditional teacher and curriculum; no homework or grades" /> The lack of a traditional teacher and curriculum; no homework or grades</label>
            <label><input type="radio" name="Concerns" value="I don't believe every child is a genius" /> I don't believe every child is a genius</label>
        </p>

        <h3 class="wide">Contact Information</h3>
        <Control>
            <Input id="contact-form-name" type="text" name="name" required />
            <Label for="contact-form-name">Name</Label>
        </Control>
        <Control>
            <Input id="contact-form-email" type="email" name="email" required />
            <Label for="contact-form-email">Email</Label>
        </Control>
        <Control>
            <Input id="contact-form-age" type="text" name="birthdate" />
            <Label for="contact-form-age">Child&rsquo;s birthdate</Label>
        </Control>
        <Control>
            <Input id="contact-form-county" type="text" name="start date" />
            <Label for="contact-form-county">Date you&rsquo;d like to start</Label>
        </Control>
        <Control className="wide">
            <Label for="contact-form-message">Additional comments</Label>
            <Textarea id="contact-form-message" name="comments" />
        </Control>
        <p>
            <button type="submit"><Button>Submit</Button></button>
        </p>
    </form>
)
