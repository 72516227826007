import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'
import PageWrapper from '../components/PageWrapper'
import Quiz from '../components/Quiz'

export default ({ data }) => (
    <Layout>
        <Helmet>
            <title>Quiz | {data.site.siteMetadata.title}</title>
        </Helmet>
        <main>
            <article>
                <PageHeader title="Step 2: Short Quiz" />
                <PageWrapper>
                    <div className="copy">
                        <p>This process is designed to make sure that we are the right school to serve the life-long learning needs for your child and your family. We recognize that we ask you to dedicate time and thought to the process. Families who are thriving in our community often share that they truly enjoyed the process, and we hope you do too.</p>
                    </div>
                    <Quiz />
                </PageWrapper>
            </article>
        </main>
    </Layout>
)
export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
