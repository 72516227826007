import React from 'react'
import { css } from '@emotion/core'

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: ''};

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    render() {
        return (
            <input
                id={this.props.id}
                type={this.props.type}
                name={this.props.name}
                value={this.state.value}
                onChange={this.handleChange}
                required={this.props.required}
                css={css`
                    display: block;
                    width: 100%;
                    appearance: none;
                    box-shadow: none;
                    border: solid var(--color-bright);
                    border-width: 0 0 1px;
                    border-radius: 0;
                    background-color: transparent;
                    font-size: 1em;
                    padding: 0.5em 0 0.5em;
                    outline: none;
                    &:focus {
                        box-shadow: inset 0 -1px 0 var(--color-bright);
                    }
                `}
            />
        )
    }
}

export default Input
